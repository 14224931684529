import React, { memo, useCallback, forwardRef } from "react";
import PropTypes from "prop-types";

const FormCustom = memo(
  forwardRef(
    (
      {
        initialValues,
        onFinish,
        onValuesChange,
        className,
        children,
        Form,
        formSettings,
      },
      ref // ref'i forwardRef ile alıyoruz
    ) => {
      const onFinishFailed = useCallback((errorInfo) => {
        const propName = errorInfo?.errorFields?.[0]?.name?.[0];
        if (propName) {
          document.getElementById(propName)?.focus();
        }
      }, []);

      return (
        <Form
          className={className}
          form={formSettings}
          autoCorrect="off"
          autoComplete="off"
          onValuesChange={onValuesChange}
          onFinish={onFinish}
          initialValues={initialValues}
          onFinishFailed={onFinishFailed}
          ref={ref} // ref'i burada geçiriyoruz
        >
          {children}
        </Form>
      );
    }
  )
);

FormCustom.propTypes = {
  initialValues: PropTypes.object,
  onFinish: PropTypes.func,
  onValuesChange: PropTypes.func,
  className: PropTypes.string,
  Form: PropTypes.elementType.isRequired,
  formSettings: PropTypes.object.isRequired,
};

export default FormCustom;
